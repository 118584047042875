<template>
  <div class="mini">
    <!-- 面试课程 -->
    <div class="part">
      <div class="title">面试课程</div>
      <el-table
      :data="msList"
      header-cell-class-name="header"
      style="width: 100%">
      <el-table-column
        prop="courseGroupName"
        label="课程名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="classTime"
        label="时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="currentPrice"
        label="培训费用">
        <template slot-scope="scope">
          <span>{{scope.row.currentPrice}} 元</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="payWay"
        label="支付方式">
        <template slot-scope="scope">
          <span>{{['全款支付','定金或全款支付'][scope.row.payWay-1]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="subtitle"
        label="备注">
      </el-table-column>
      <el-table-column
        prop="classLocation"
        label="上课地点">
      </el-table-column>
      <el-table-column
        align="center"
        label="点击报名">
        <template slot-scope="scope">
          <div class="buy" @click="toBuy(scope.row)" v-if="btnStateComputed(scope.row).canClick">{{btnStateComputed(scope.row).state}}</div>
          <div class="buy buydisable" v-if="!btnStateComputed(scope.row).canClick">{{btnStateComputed(scope.row).state}}</div>
        </template>
      </el-table-column>
      
    </el-table>
    </div>
    <!-- 笔试课程 -->
     <div class="part">
      <div class="title">笔试课程</div>
      <el-table
        :data="bsList"
        header-cell-class-name="header"
        style="width: 100%">
        <el-table-column
          prop="courseGroupName"
          label="课程名称">
        </el-table-column>
        <el-table-column
          prop="classTime"
          label="时间"
          width="180">
        </el-table-column>
        <el-table-column
          prop="currentPrice"
          label="培训费用">
          <template slot-scope="scope">
            <span>{{scope.row.currentPrice}} 元</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payWay"
          label="支付方式">
          <template slot-scope="scope">
            <span>{{['全款支付','定金或全款支付'][scope.row.payWay-1]}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subtitle"
          label="备注">
        </el-table-column>
        <el-table-column
          prop="classLocation"
          label="上课地点">
        </el-table-column>
        <el-table-column
          align="center"
          label="点击报名">
          <template slot-scope="scope">
            <div class="buy" @click="toBuy(scope.row)" v-if="btnStateComputed(scope.row).canClick">{{btnStateComputed(scope.row).state}}</div>
            <div class="buy buydisable" v-if="!btnStateComputed(scope.row).canClick">{{btnStateComputed(scope.row).state}}</div>
          </template>
        </el-table-column>
        
      </el-table>
    </div>
  </div>
</template>

<script>
import { 
  getCourseGroup,
  getCourseGroupDetail,
  checkBeforeCreate,
  getLessonGroupTips
} from '@/api/api'
import { MINIBTNSTATEFILTER } from '@/config/config'


export default {
  name: 'mini',
  data() {
    return {
      page: 1,
      limit: 50,
      bsList: [],
      msList: [],
      detail: null,
    }
  },
  async mounted() {
    this.bsList = await this.getCourseGroup('笔试')
    this.msList = await this.getCourseGroup('面试')
    
  },
  computed: {
    btnStateComputed() {
      return (item) => {
        return MINIBTNSTATEFILTER(item)
      }
    },
  },
  methods: {
    async getLessonGroupTips(id) {
      let params = {
        courseGroupId: id
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return null
      }
      
      return response.data.orderId
    },
    async getCourseGroupDetail (id) {
      let response = await getCourseGroupDetail({id: id})
      if(+response.returnCode !== 10001) {
        return null
      }
      return response.data
      
    },
    async checkBeforeCreate(val) {
      
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      } else {
        return true
      }
      
    },
    async toSingup(val) {
      // 关联职位列表，跳转报名步骤 非关联的，就直接跳支付
      let canBuy = await this.checkBeforeCreate()
      if(!canBuy) {
        return false
      }
      // this.$router.push({path: `/course/signup?step=2`})
      if(+val === 1) {
        this.$router.push({path: `/course/signup`})
      } else {
        this.$router.push({path: `/course/signup?step=2`})
      }
    },
    async toBuy(item) {
      let courseDetail = await this.getCourseGroupDetail(item.id)
      let orderId = await this.getLessonGroupTips(item.id)
      this.detail = courseDetail
      let type = MINIBTNSTATEFILTER(courseDetail).type
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$store.dispatch('setOrderInfo',{
            courseGroupId: courseDetail.id,
            courseGroupName: courseDetail.courseGroupName,
            jobListId: courseDetail.jobListId,
            loCourseInfo: courseDetail
          })
          this.toSingup(courseDetail.interviewFlag)
          // this.$nextTick(() => {
          //   this.$emit('confirm',courseDetail.interviewFlag)
          // })
        } else {
          this.$store.dispatch('toggleDialog',{isShow: true, type:0})
        }
      } else {
        this.$router.push({path: `/course/signup?step=3&orderId=${orderId}`})
      }
    },
    async getCourseGroup(type) {
      const params = {
        categoryName: type,
        page: this.page,
        limit: this.limit
      }
     
      let response = await getCourseGroup(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      return response.data
    },
  }
}
</script>

<style lang="less" scoped>
.mini{
  padding: 30px 0;
  min-height: 70vh;
}
.part{
  width: 1200px;
  margin: 0 auto 30px;
}
.title{
  border-left: 4px solid #E43635;
  height: 44px;
  line-height: 44px;
  background: #F2F2F2;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  padding-left: 18px;
}
.header{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 14px;
}
.buy{
  display: inline-block;
  min-width: 62px;
  padding: 0 18px;
  height: 27px;
  background: #E43635;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.buydisable{
  background: #999!important;
}
</style>
<style>
.el-table{
  color:#333333;
}
.el-table thead{
  color:#333333;
}
</style>